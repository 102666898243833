import * as ReactDOM from "react-dom/client";
import * as React from "react";
import { redirect, createBrowserRouter, RouterProvider } from 'react-router-dom';
import axios from 'axios';

import ErrorIntercept from './components/ErrorIntercept';
import Home from './components/Home';
import PageLayout from './components/PageLayout';
import Report from './components/Report';

import { reportLoader } from "./loaders/reportLoader";

import baseURL from './config/baseURL';

import 'rsuite/dist/rsuite.min.css';
import './beacon.css';

const router = createBrowserRouter([
  {
    path: '/authenticate',
    errorElement: <ErrorIntercept />,
    loader: async ({ request }) => {
      const searchParams = new URL(request.url).searchParams;
      const email = searchParams.get('email');
      const hash = searchParams.get('hash');
      const expiration_date = searchParams.get('expiration_date');
      if (!email || !hash || !expiration_date) {
        throw new Error('Missing required parameters');
      }
      // Make an API call to the Flask authentication endpoint
      // This will throw an error if auth fails
      await axios.get(
        `${baseURL}/authenticate?email=${email}&hash=${hash}&expiration_date=${expiration_date}`,
        {
          withCredentials: true,
        }
      );
      return redirect('/');
    }
  },
  {
    element: <PageLayout />,
    loader: async () => await axios.get(`${baseURL}/explain`, { withCredentials: true }),
    errorElement: <ErrorIntercept />,
    children: [
      {
        path: '/',
        element: <Home />,
        loader: async () => {
          const response = await axios.get(
            `${baseURL}/dashboard`,
            {
              withCredentials: true,
            }
          );
          return response;
        }
      },
      {
        path: '/engagement/*',
        element: <Report />,
        loader: reportLoader,
      },
      {
        path: '/conversion/*',
        element: <Report />,
        loader: reportLoader,
      },
    ]
  },
]);

ReactDOM.createRoot(document.getElementById('root')).render(
  <RouterProvider router={router} />
);