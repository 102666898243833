import React, { useState } from 'react';

import { Sidebar, Sidenav, Nav, Drawer, ButtonToolbar, IconButton, Panel } from 'rsuite';
import { useLocation, useSearchParams } from 'react-router-dom';
import { SettingHorizontal } from '@rsuite/icons';
import ReportDateRange from './ReportDateRange';
import WrappedNavItem from './WrappedNavItem';
import ReportFilters from './ReportFilters';

function sidenavFromPlan(reports, pathname) {
  // get first level of url
  const toplevel = pathname.split('/')[1];
  return reports[toplevel];
}

function activeReport(sidenav, pathname) {
  const reportPath = pathname.split('/')[2];
  if (reportPath === undefined) {
    return sidenav[0];
  }
  return sidenav.find((item) => item.path === reportPath);
}

function ReportSidebar({ reports }) {
  const location = useLocation();
  const sidenav = sidenavFromPlan(reports, location.pathname);
  const active = activeReport(sidenav, location.pathname);

  const [searchParams, setSearchParams] = useSearchParams();

  const [dateRange, setDateRange] = useState({ start_date: searchParams.get('start_date'), end_date: searchParams.get('end_date') });

  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState();

  const handleOpen = key => {
    setOpen(true);
    setPlacement(key);
  };

  const handleRangeChange = (range) => {
    setDateRange(range);
    setSearchParams({ ...searchParams, ...range})
  }

  const countFilters = () => active.filters.filter((filter) => searchParams.has(filter)).length;

  const filterButton = () => {
    if (active.filters && active.filters.length > 0) {
      return (
        <Panel className="filter-panel">
          <ButtonToolbar>
            <IconButton icon={<SettingHorizontal />} onClick={() => handleOpen('bottom')}>
              Filters ({countFilters()} Active)
            </IconButton>
          </ButtonToolbar>
        </Panel>
      );
    }
  };

  return (
    <>
      <Sidebar className='navigation-sidebar'>
        <Sidenav>
          <Sidenav.Body>
            <ReportDateRange
              earliest={active.earliest_date}
              onRangeUpdate={handleRangeChange}
            />
            {filterButton()}
            <Nav>
              {sidenav.map((plan) => (
                <WrappedNavItem indent={plan.indent || 0} href={plan.path + location.search} text={plan.title} key={plan.path} />
              ))}
            </Nav>
          </Sidenav.Body>
        </Sidenav>
      </Sidebar>
      <Drawer placement={placement} open={open} onClose={() => setOpen(false)}>
        <ReportFilters closeDrawerHandler={() => setOpen(false)} report={active} dateRange={dateRange} />  
      </Drawer>
    </>
  );
}

export default ReportSidebar;