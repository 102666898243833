import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts'

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip" style={{background: '#fff', padding: '5px', border: 'solid 1px #efefef'}}>
        <p className="label">{`${payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};

const SynchronizedTimeSeries = ({ data }) => (
  <div>
    {data.order.map((stat) => (
      <ResponsiveContainer height={150} width="30%" key={stat} style={{ width: '40%', display: 'inline-block', float: 'left' }}>
        <h5 style={{ fontSize: '14px'}}>{data.labels[stat]}</h5>
        <LineChart
          width={500}
          height={200}
          data={data.stats.reverse()}
          syncId="anyId"
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 20,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="DAY" />
          <YAxis dataKey={stat} />
          <Tooltip content={CustomTooltip} />
          <Line type="monotone" dataKey={stat} stroke="#8884d8" fill="#8884d8" />
        </LineChart>
      </ResponsiveContainer>
    ))}
  </div>
);

export default SynchronizedTimeSeries;