import React from 'react';

import { Outlet } from "react-router-dom";
import { Container, Header, Footer, Navbar, Nav, Panel } from 'rsuite';
import { BarLineChart, Global, Growth } from '@rsuite/icons';
import WrappedNavItem from './WrappedNavItem';
import { AppProvider } from '../contexts/appContext';
import PublisherPicker from './PublisherPicker';

function PageLayout() {
  return (
    <div>
      <AppProvider>
        <Container>
          <Header className='beaconbar'>
            <Navbar appearance="inverse">
              <Navbar.Brand>
                {/* <img src="/beacon.png" alt="Lede Beacon" height="28" className="logo" /> */}
                Lede Beacon
              </Navbar.Brand>
              <Nav>
                <WrappedNavItem href='/' text='Home' icon={<Global />} />
                <WrappedNavItem href='/engagement' text='Engagement' icon={<BarLineChart />} />
                <WrappedNavItem href='/conversion' text='Conversion' icon={<Growth />} />
              </Nav>
              <Nav pullRight className="publisher-picker">
                <PublisherPicker />
              </Nav>
            </Navbar>
          </Header>
          <Container>
            <Outlet />
          </Container>
          <Footer>
            <Panel>
              &copy; {(new Date()).getFullYear()} Alley Lede, Inc.
            </Panel>
          </Footer>
        </Container>
      </AppProvider>
    </div>
  );
}

export default PageLayout;
