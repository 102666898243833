import React from 'react';
import { useLoaderData } from 'react-router-dom';
import { Content, Sidebar, Container, Header } from 'rsuite';
import SynchronizedTimeSeries from './SynchronizedTimeSeries';
import { Link } from 'react-router-dom';

function Home() {
  const { data } = useLoaderData();
  if (data.message) { 
    return <Container><Content><p>{data.message}</p></Content></Container>;
  }
  return (
    <Container>
      {/* <Panel className="readme">
        <h3>Welcome to Lede Beacon Beta</h3>
        <p>Lede Beacon is a tool to better understand the performance of your website.</p>
        <p>This is beta software! Please do not use Lede's helpdesk functionality to request assistence with this tool! Regular office hours will be shared by the Lede team for Q&A during the beta period of this tool.</p>
        <p>The <strong>engagement</strong> tab shares various reports that allow you understand how traffic arrives at your site as well as the magnitude of traffic, similar to what you might see in a basic Google Analytics report.</p>
        <p>The <strong>acquisition</strong> tab shares information about what users do before purchasing a subscription or signing up for an email.</p>
      </Panel> */}
      <Header className='top-matter'>
        <Content>
          { data.top_stats_order.map((stat) => (
            <div className='home-stat' key={stat}>
              <h5>{data.labels[stat]}</h5>
              <h2>{data.yesterday[stat]}</h2>
              <p>{data.top_stats_sublabels[stat]}</p>
            </div>
          ))}
        </Content>
      </Header>
      <Container>
        <Sidebar>
          <h6 style={{ fontSize: 14 }}>Yesterday's Top Articles</h6>
          <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
            {data.top_articles.map((article) => (
              <li key={article.CONTENTID} style={{ marginBottom: 8 }}>
                <Link to={`/engagement/summary?article=${article.CONTENTID}`}>{article.PAGETITLE}</Link>
              </li>
            ))}
          </ul>
        </Sidebar>
        <Content>
          <SynchronizedTimeSeries data={data} />
        </Content>
      </Container>
    </Container>
  );
}

export default Home;
